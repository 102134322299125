import Footer from "../../../Footer"
import Header from "../../../Header"
import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import RequisicaoPut from "../../../../config/requisicoes/requisicaoPUT";
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";

interface Props {
    funcionario?: any
    isDisabled?: any
    formType?: string
}


const FormEditarFuncionario = ({ funcionario, isDisabled, formType }: Props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [embId, setEmbId] = useState(0);
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [isLoading, setIsLoading] = useState(['Salvar', false]);

    const { dadosFuncionario } = useContext(EmbarcacaoContext)

    const navigate = useNavigate();

    console.log('funcionario = ', dadosFuncionario);

    async function atualizarDados(data: any) {
        setIsLoading(['Carregando...', true]);
        //await new Promise(resolve => setTimeout(resolve, 5000));


        const dataAtual = new Date();
        const dataHoraAtual = dataAtual.toISOString().replace('T', ' ').replace('Z', '');
        console.log(dataHoraAtual); 

        data.data = dataHoraAtual;
        data.id = Number(dadosFuncionario?.id);

        console.log('ENTROU SALVAR DADOS = ');
        console.log('data salvar dados = ', data);

        const resultado = await RequisicaoPut('/funcionario', { data, id: Number(dadosFuncionario?.id) });
        console.log('resultado = ', resultado);

        if (resultado.status === "success") {
            console.log('sucesso');
            navigate('/funcionarios');
        } else {
            alert('Ocorreu um erro ao salvar as alterações !');
            return;
        }
    }

    return (
        <>

            <Form onSubmit={handleSubmit(atualizarDados)}>
                <section>
                    <Form.Group key="a" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Data Admissão:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('dataAdmissao', { required: true })} disabled={isDisabled} defaultValue={dadosFuncionario?.dataAdmissao.slice(0, 10)} type="date"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="b" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Nome:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('nome', { required: true })} disabled={isDisabled} defaultValue={dadosFuncionario?.nome} type="text"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="c" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Cargo:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('cargo', { required: false })} disabled={isDisabled} defaultValue={dadosFuncionario?.cargo} type="text"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>


                    <Form.Group key="d" as={Col}>
                        <Form.Label sm={1}>
                            Status:
                        </Form.Label>

                        <Form.Select as={Row}  {...register('status', { required: false })} disabled={isDisabled} defaultValue={dadosFuncionario?.status} type="text" >
                            <option value="Ativo">Ativo</option>
                            <option value="Inativo">Inativo</option>
                        </Form.Select>
                    </Form.Group>

                    <Button type="submit" disabled={!!isLoading[1]}>{isLoading[0]}</Button>


                </section>

            </Form>
        </>
    )
}

export default FormEditarFuncionario;