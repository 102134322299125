import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from './style.module.css';

const MainPage = () => {

    if (navigator.onLine) {
        console.log('online');
    } else {
        console.log('offline');
    }

    return (

        <>
            <Header></Header>

            <h2>Notícias</h2>

            <div className={styles.container}>

                <div className={styles.card}>
                    <div className={styles.conteudo}>
                        <h2 className={styles.titulo}>{"Nova Funcionalidade: Agendamento de Serviços (BETA)"}</h2>
                        
                        <p className={styles.descricao}>{"11/03/2025: Agora é possivel agendar a descida de uma embarcacao para uma data futura. Basta selecionar uma data futura na hora de cadastrar a OS e o sistema destacará essa OS (em amarelo) como um agendamento futuro."}</p>
                    </div>
                </div>


                <div className={styles.card}>
                    <div className={styles.conteudo}>
                        <h2 className={styles.titulo}>{"Mudanças no cadastro de embarcões"}</h2>
                        <p className={styles.descricao}>{"Fique atento: O Cadastro de novas embarcaões passou por atualizações e agora campos como Modelo e Motor devem ser preenchidos !"}</p>
                    </div>
                </div>


            </div>


            <Footer />
        </>

    )
}

export default MainPage;