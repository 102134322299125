import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import RequisicaoPost from "../../../../../config/requisicoes/requisicaoPOST";


interface Props {
    limpeza?: any
    isDisabled?: any
    formType?: string
}

const FormNovaLimpeza = ({ limpeza, isDisabled, formType }: Props) => {
    const { handleSubmit, register} = useForm();
    const [embId, setEmbId] = useState(0);
    const [funcId, setFuncId] = useState(0);
    const [isLoading, setIsLoading] = useState(['Salvar', false]);  

    const navigate = useNavigate();

    async function pesquisaEmbarcacao(data: any) {
        const newData = { ...data, data }
        if (newData.length < 3) {
            return;
        }
        const resultado = await RequisicaoPost('/pesquisarEmbarcacaoes', { formaPesquisa: "Id Embarcação", infoParaPesquisa: newData.data })
        const embarcacoes = resultado.resultado.rows;
        const embarcacoesFormatado = embarcacoes.map((item: any) => ({ value: item?.id, label: item?.id + '-' + item?.Cliente?.nome.slice(0, 20) + '/' + item?.tipo + '/' + item?.modeloCascoEmb }))
        return embarcacoesFormatado;
    }

    async function pesquisaFuncionario(data: any) {
        const newData = { ...data, data }
        const resultado = await RequisicaoPost('/funcionarioByName', {...newData})
        const funcionarios = resultado.rows;
        funcionarios.map((item:any) => console.log('item = ', item));
        const funcionariosFormatado = funcionarios.map((item: any) => ({ value: item?.id, label: item?.id + '-' + item?.nome }))
        return funcionariosFormatado;
    }

    function setEmbSelecionada(data: any) {
        setEmbId(data.value);
    }

    async function salvarDados(data: any) {
        setIsLoading(['Carregando...', true]);

        const dataAtual = new Date();
        const dataHoraAtual = dataAtual.toISOString().replace('T', ' ').replace('Z', '');
        console.log(dataHoraAtual); // Output: "2023-02-20 14:30:00"

        //data.data = dataHoraAtual;
        data.embarcacaoId = embId;
        data.funcionarioId = funcId;

        const resultado = await RequisicaoPost('/novaLimpeza', { data });

        if(resultado.status === "success") {
            navigate('/limpezasMain');
        }else{
            alert('Ocorreu um erro ao salvar a Limpeza !');
            setIsLoading(['Salvar', false]);
            return;
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit(salvarDados)}>
                <section>
                    <Form.Group key="a" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Data:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('data', { required: true })} disabled={isDisabled} defaultValue={new Date().toISOString().slice(0, 10)} type="date"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="b" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Tipo:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Select  {...register('tipoServico', { required: true })} disabled={isDisabled}>
                                    <option>Carga Bateria</option>
                                    <option>Limpeza</option>
                                    <option>Repasse</option>
                                    <option selected>Limpeza</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="c" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Embarcacao:
                            </Form.Label>
                            <Col xs={5}>
                                <AsyncSelect {...register("embarcacao")} placeholder={"Digite Código Embarcacao"} cacheOptions loadOptions={pesquisaEmbarcacao} defaultOptions onChange={(data: any) => { setEmbSelecionada(data) }}></AsyncSelect>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="d" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Funcionário:
                            </Form.Label>
                            <Col xs={5}>
                            <AsyncSelect {...register("funcionario")} placeholder={"Pesquise Funcionário"} cacheOptions loadOptions={pesquisaFuncionario} defaultOptions onChange={(data: any) => { setFuncId(Number(data.value)) }}></AsyncSelect>
                                
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="e" as={Col} className="mb-4">
                        <Col>
                            <Form.Label>Observação:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("observacao")} as="textarea" rows={5} disabled={isDisabled} defaultValue={limpeza?.observacao} ></Form.Control>
                        </Col>

                    </Form.Group>

                    <Button type="submit" disabled={!!isLoading[1]}>{isLoading[0]}</Button>


                </section>

            </Form>
        </>
    )
}

export default FormNovaLimpeza;