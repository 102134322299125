import { useState, createContext, useEffect } from 'react';
import RequisicaoGet from '../config/requisicoes/requisicaoGET';

export const LimpezaContext = createContext();

export const LimpezaContextProvider = ({ children }) => {
    const today = new Date().toISOString().split("T")[0];
    console.log('today LimpezaContext = ', today);

    const sevenDayAgo = new Date();
    sevenDayAgo.setDate(sevenDayAgo.getDate() - 7);
    const sevenDayAgoString = sevenDayAgo.toISOString().split("T")[0];

    const [dadosLimpeza, setDadosLimpeza] = useState([]);
    const [dataInicio, setDataInicio] = useState(sevenDayAgoString);
    const [dataFim, setDataFim] = useState(today);

    function hadleDataTransform(data) {
        let groupedData = [];
        data?.forEach((item) => {
            console.log(item.tipo === null);

            let limpezasJetski = 0;
            let limpezasLancha = 0;

            if (!groupedData[item.funcionarioNome]) {
                if(item['Embarcaco.tipo'] === 'Jet-Ski') {
                    limpezasJetski = Number(item.totalLimpezas)//1;
                }else if(item['Embarcaco.tipo'] === 'Lancha' || item['Embarcaco.tipo'] === 'Barco Pesca' || item['Embarcaco.tipo'] === null) {
                    limpezasLancha = Number(item.totalLimpezas);
                }else {
                    console.log('ENTROU ELSE');
                    console.log('item = ', item);
                    limpezasLancha = Number(item.totalLimpezas);
                }
                groupedData[item.funcionarioNome] = {
                    totalLimpezas: Number(item.totalLimpezas),
                    funcionarioNome: item['Funcionario.nome'],
                    funcionarioId: item.funcionarioId,
                    limpezasJetski: limpezasJetski,
                    limpezasLancha: limpezasLancha,
                }
            } else {
                if(item['Embarcaco.tipo'] === 'Jet-Ski') {
                    limpezasJetski = Number(groupedData[item.funcionarioNome].limpezasJetski) + Number(item.totalLimpezas);
                    limpezasLancha = Number(groupedData[item.funcionarioNome].limpezasLancha);
                }else if(item['Embarcaco.tipo'] === 'Lancha' || item['Embarcaco.tipo'] === 'Barco Pesca' || item['Embarcaco.tipo'] === null) {
                    limpezasLancha = Number(groupedData[item.funcionarioNome].limpezasLancha) + Number(item.totalLimpezas);
                    limpezasJetski = Number(groupedData[item.funcionarioNome].limpezasJetski);
                }else {
                    limpezasLancha = Number(groupedData[item.funcionarioNome].limpezasLancha) + Number(item.totalLimpezas);

                }
                groupedData[item.funcionarioNome] = {
                    totalLimpezas: Number(groupedData[item.funcionarioNome].totalLimpezas) + Number(item.totalLimpezas),
                    funcionarioNome: item['Funcionario.nome'],
                    funcionarioId: item.funcionarioId,
                    limpezasJetski: limpezasJetski,
                    limpezasLancha: limpezasLancha,
                }
            }
        })

        const arrayGroupedData = Object.entries(groupedData).map(([key, value]) => ({ key, ...value }));
        return arrayGroupedData;
    }


    async function getCleaningByDate(dataInicio, dataFim, embarcacaoId) {
        const Cleaning = await RequisicaoGet('/limpezas?dataInicio=' + dataInicio + '&dataFim=' + dataFim + '&page=0&size=50' + (embarcacaoId ? '&embarcacaoId=' + embarcacaoId : ''));
        Cleaning.groupByEmployee = hadleDataTransform(Cleaning.groupByEmployee);
        setDadosLimpeza(Cleaning);
        return Cleaning;
    }

    async function handleSearch(data) {
        const limpezas = await RequisicaoGet(`/limpeza/${data}`);
        setDadosLimpeza(limpezas.rows);
    }


    return (
        <LimpezaContext.Provider value={{ 
            dadosLimpeza,
            setDadosLimpeza,
            dataInicio,
            setDataInicio,
            dataFim,
            setDataFim,
            getCleaningByDate,
            handleSearch
        }}
        >
            {children}
        </LimpezaContext.Provider>
    )
}