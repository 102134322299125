
import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import ComponentPagination from "../../Pagination";
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import DateRangeForm from "../../DateRangeForm";
import { LimpezaContext } from "../../../Context/LimpezaContext";

interface Props {
    limpezas: any
    roteDetalhar: string
    count?: any
}


const TableLimpezas = ({ limpezas, roteDetalhar, count }: Props) => {

    const { dadosLimpeza, setDadosLimpeza, getCleaningByDate, dataInicio, dataFim } = useContext(LimpezaContext);
    const { currentPage } = useContext(ClienteContext);
    const navigate = useNavigate();

    useEffect(() => {
    }, [dadosLimpeza, currentPage]);

    function RedirectTo(paramters: any, rote: string) {
        console.log('Parametros Venda = ', paramters);
        console.log('rote = ', rote);
        setDadosLimpeza(paramters);
        return navigate(rote);
    }


    return (
        <>
            <DateRangeForm />

            <table style={{ margin: 0, marginRight: 'auto', marginLeft: 'auto', width: '30%', borderCollapse: 'collapse' }}>
                <tr>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Funcionário</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Jets</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Lanchas</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Quant. Limpezas Período</th>
                </tr>

                {dadosLimpeza?.groupByEmployee?.map((item: any) => {
                    console.log('item 456 = ', item);
                    return (
                        <tr>
                            <td style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{item?.funcionarioNome}</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{item?.limpezasJetski}</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{item?.limpezasLancha}</td>
                            <td style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{item?.totalLimpezas}</td>
                        </tr>)
                })
                }

                <tr>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Total</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{limpezas?.limpezasJets}</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{limpezas?.limpezasTotal - limpezas?.limpezasJets}</th>
                    <th style={{ border: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>{limpezas?.limpezasTotal}</th>

                </tr>
            </table>

            <div style={{ marginTop: 20 }}>
                <Form.Group className="mb-3" >
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Col sm={3} className="mb-4" >
                            <Form.Control placeholder="Pesquisar Limpeza por emb id" type="text" onChange={(e) => getCleaningByDate(dataInicio, dataFim, (e.target.value))} />
                        </Col>
                    </Row>
                </Form.Group>
            </div>

            <div style={{ color: 'red', fontSize: 20, marginBottom: 20 }}>
                Limpezas Pendentes: {dadosLimpeza?.limpezasPendentes?.total} <br />
                <div style={{ fontSize: 16 }}>Jets: {dadosLimpeza?.limpezasPendentes?.jets} / Lanchas: {dadosLimpeza?.limpezasPendentes?.barcos} </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Cliente</th>
                        <th>Embarcacao</th>
                        <th>Tipo Serviço</th>
                        <th>Funcionário</th>
                        <th>Observação</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {dadosLimpeza?.limpezas?.rows?.map((item: any) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.data?.toString().slice(0, 10)}</td>
                            <td>{item?.Embarcaco?.Cliente?.nome}</td>
                            <td>{item?.Embarcaco?.id + "-" + item?.Embarcaco?.nome + " - " + item?.Embarcaco?.modeloCascoEmb}</td>
                            <td>{item?.tipoServico}</td>
                            <td>{item?.Funcionario?.nome}</td>
                            <td>{item?.observacao.slice(0, 30)}</td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item, roteDetalhar)}>+</Button></td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableLimpezas;