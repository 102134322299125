import react, { useEffect, useState, useContext } from "react";
import Header from "../../../../components/Header"
import Footer from "../../../../components/Footer";
import { Button, Table } from "react-bootstrap";
import FormNovaLimpeza from "../../../../components/Forms/Embarcacoes/Limpezas/FormNovaLimpeza";
import TableLimpezas from "../../../../components/Tables/TableLimpezaMain";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import Limpeza from "../../../../shared/Types/Limpeza";
import { LimpezaContext } from "../../../../Context/LimpezaContext";
import { set } from "react-hook-form";


const PageLimpezasMain = () => {

    const { getCleaningByDate, dataInicio, dataFim, setDadosLimpeza, dadosLimpeza } = useContext(LimpezaContext);
    const [dataTable, setDataTable] = useState<Limpeza[]>([]);
    const [count, setCount] = useState({});


    async function getAllLimpezas() {
        console.log('------- INICIOU getAllLimpezas on PageLimpezasMain --------');
        console.log('dataInicio PageMain= ', dataInicio);
        console.log('dataFim PageMain= ', dataFim);
        //const limpezas = await RequisicaoGet(`/limpezas/?page=${0}&size=50`);
        const limpezas = await getCleaningByDate(dataInicio, dataFim);
        console.log('limpezas xxx =', limpezas);
        console.log('last7Days = ', limpezas.last7days);
        
        //setDataTable(limpezas.rows);
        setDadosLimpeza(limpezas);
        /*setCount({
            limpezasJets: limpezas.limpezasJets,
            limpezasTotal: limpezas.limpezasTotal,
            last7Days: limpezas.last7days,
            limpezasPendentes: limpezas.limpezasPendentes
        })*/
        console.log(`dadosLimpeza MainPage = `, dadosLimpeza);
    }
    
    useEffect(() => { getAllLimpezas() }, []);
    //useEffect(() => { getAllLimpezas()} , [dataTable]);


    return (
        <>
            <Header />
            <h1>Page Limpezas Main</h1>

            <Button href="/novaLimpeza">Nova Limpeza</Button>

            <TableLimpezas
                limpezas={dadosLimpeza}
                roteDetalhar='/detalharLimpeza'
                count={count}
            />
            <Footer />
        </>
    )
}

export default PageLimpezasMain;