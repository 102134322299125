import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import { ClienteContext } from "../../../Context/ClienteContext";
import DropDownButton from "../../DropdownButton";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import NavDropdown from 'react-bootstrap/NavDropdown';
import styles from './TableServicoRampa.module.css'
import greenGas from '../../../assets/greenGas.png'
import redGas from '../../../assets/redGas.png'
import greyGas from '../../../assets/greyGas.png'
import ModalGas from "../../../components/Modals/ModalGas"
import { Badge } from "react-bootstrap";

const TableServicoRampa = (props) => {

    const { setDadosCliente, currentPage, setCurrentPage } = useContext(ClienteContext);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [statusModal, setStatusModal] = useState(false);
    const [dataModal, setDataModal] = useState({});

    const [totalEmbsNaAgua, setTotalEmbsNaAgua] = useState();
    const [embsNaFila, setEmbsNaFila] = useState();
    const [jetsNaAgua, setJetsNaAgua] = useState();
    const [emAtendimento, setEmAtendimento] = useState();
    const [subir, setSubir] = useState();
    const [estoqueGasolina, setEstoqueGasolina] = useState();
    const [atendimentosDia, setAtendimentosDia] = useState();
    const [embId, setEmbId] = useState();

    const navigate = useNavigate();


    useEffect(() => {
        if (Object.keys(filtrosAplicados).length > 0) {
            aplicarFiltro();
            console.log('Filtrou1')
        }
        if (Object.keys(filtrosAplicados).length === 0 && props.type === "main") {
            aplicarFiltro();
            console.log('Filtrou2')

        }
    },
        [filtrosAplicados, currentPage]
    );



    function RedirectTo(paramters, rote) {
        const DadosOS = { ...paramters, isReadOnly: 1 };
        setDadosCliente(DadosOS);
        return navigate(rote);
    }

    function defineCorBadge(statusAtendimento) {
        if (statusAtendimento === "Na Fila") {
            return "danger";
        } else if (statusAtendimento === "Em Atendimento") {
            return "warning";
        } else if (statusAtendimento === "Na Agua") {
            return "info"
        } else if (statusAtendimento === "Subir") {
            return "success"
        } else if (statusAtendimento === "Finalizado") {
            return "secondary"
        }

    }

    async function atualizaStatusAtendimento(id, novoStatus) {
        console.log(' xxxxxatualizaStatusAtendimento');

        console.log('id = ', id[0]);
        console.log('Novo status = ', novoStatus);
        const embarcacaoId = Number(id[1]);
        console.log('embarcacaoId = ', embarcacaoId);
        const AtualizaStatus = await RequisicaoPost('/atualizaStatusAtendimento', { id: Number(id[0]), statusAtendimento: novoStatus[0], embarcacaoId: embarcacaoId });
        console.log('AtualizaStatus = ', AtualizaStatus);

        //props.atualizarDados(); //atualiza contadores na pagina principal de servicos rampa
        aplicarFiltro(); //atualiza dados dentro da tabele
    }

    async function atualizaFiltros(novofiltro) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro })); //garante atualizacao do state sem precisar renderizar novamente a pagina
    }

    async function aplicarFiltro(parametrosFiltro) {
        const dadosFiltrados = await RequisicaoPost(`/getAllOS/?page=${currentPage}&size=50`, filtrosAplicados);
        console.log('dados Filtrados = ', dadosFiltrados);
        setDataTable(dadosFiltrados?.AllOS?.rows);

        setTotalEmbsNaAgua(dadosFiltrados.qtdadeTotalEmbsNaAgua);
        setEmbsNaFila(dadosFiltrados.qtdadeEmbsNaFila);
        setJetsNaAgua(dadosFiltrados.qtdadeJetsNaAgua);
        setEmAtendimento(dadosFiltrados.qtdadeEmbsAtendimento);
        setSubir(dadosFiltrados.qtdadeEmbsSubir)
        setEstoqueGasolina(dadosFiltrados.qtdadeGaloesGas.qtdadeEstoque);
        setAtendimentosDia(dadosFiltrados.qtdadeAtendimentosDia);

    }

    function selecionarDescida(arrayDescida, OSEmbId, dataOS) {
        const resultado = arrayDescida.filter(desc => desc.embarcacaoId === OSEmbId && desc.data === dataOS)
        return `Descida Avulsa ${resultado[0]?.numeroDescida}`
    }

    function retornaTipoServico(item) {
        if (item?.tipoServico === 'Descida Avulsa') {
            return selecionarDescida(item?.Cliente?.Descidas, item.embarcacaoId, item?.dataSolicitacao?.slice(0, 10));
        } else if (item?.tipoServico === 'Abastecer & Levar') {
            return <>Abastecer & <strong style={{ color: "orange" }}>Levar</strong></>
        } else if (item?.tipoServico === 'Levar')
            return <strong style={{ color: "orange" }}>Levar</strong>

        else {
            return item?.tipoServico
        }
    }

    async function pesquisaOS(alvoBusca) {
        atualizaFiltros({ nome: alvoBusca })
    }



    function abrirModalGas(item) { //abrir modal de confirmacao
        setDataModal(item);
        setStatusModal(true);
    }

    function fecharModal() {
        setStatusModal(false);
    }

    async function uptadeGas(qtdadeCombAtualizada) {
        const att = await RequisicaoPost('/atualizarStatusAbastecimento',
            {
                id: dataModal.id,
                qtdadeCombustivel: qtdadeCombAtualizada,
                statusAbastecimento: 'Abastecido',
                products: [{ value: 17, label: 'Gasolina' }],
                clienteId: Number(dataModal?.Cliente?.id),
            }
        );
        if (att.status !== "success") {
            alert('Ocorreu um erro. Tente Novamente')
            fecharModal();
            return;
        }

        fecharModal();
        aplicarFiltro();
        props.atualizarDados();
    }

    function retornaAgendamento(dataPrevAtendimento, dataSolicitacao, status) {
        console.log('Entrou retornaAgendamento \n');
        if (dataPrevAtendimento === null || dataPrevAtendimento === undefined) {
            return dataSolicitacao;
        } else {
            console.log('dataPrevAtendimento = ', new Date(dataPrevAtendimento));
            return new Date(dataPrevAtendimento).toISOString().split('T')[0] + ' - ' + ' (Agendado)';
        }

    }

    return (
        <>
            <ModalGas
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={uptadeGas}
                titulo='Confirmar Abastecimento'
                cliente={dataModal?.Cliente?.nome}
                embarcacao={` ${dataModal?.Embarcaco?.id} - ${dataModal?.Embarcaco?.nome} - ${dataModal?.Embarcaco?.modeloCascoEmb}`}
                quantidade={dataModal?.qtadeCombustivel}

            />

            <h2>Fila de Atendimento</h2>
            <p>Atendimentos dia: {atendimentosDia} </p>
            <h4>
                <Badge bg="primary">Na Água: {totalEmbsNaAgua} <br /> B: {totalEmbsNaAgua - jetsNaAgua} / J:{jetsNaAgua}</Badge>
                <Badge bg="danger">   Na fila: {embsNaFila}  </Badge>
                <Badge bg="warning">Em Atendimento: {emAtendimento}</Badge>
                <Badge bg="success">Subir: {subir}</Badge>


            </h4>

            <h5>Estoque Gasolina: {estoqueGasolina / 20} galões</h5>

            <div id={styles.headerTable}>
                <div id={styles.divBusca}>
                    <input
                        id={styles.txtBusca}
                        type="text"
                        onChange={(e) => pesquisaOS(e.target.value)}
                        placeholder="Pesquisar Cliente"
                    />
                </div>
                <br />
                <br />

                <div id={styles.filtroArea}>
                    <Button variant="secondary" size="sm"
                        onClick={() => {
                            setFiltrosAplicados(filtro => ({}));
                            setCurrentPage(page => (0));
                            aplicarFiltro(filtro => ({}));
                        }
                        }
                    >
                        Limpar Filtros
                    </Button>

                    <div>
                        <p>Filtros Aplicados: {Object.values(filtrosAplicados)}</p>
                    </div>

                </div>
            </div>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>OS</th>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Proprietário</th>
                        <th>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Tipo Emb"
                                menuVariant="dark"
                            >

                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Barco Pesca' })}>Barco Pesca</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Jet-Ski' })}>Jet-Ski</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ tipo: 'Lancha' })}>Lancha</NavDropdown.Item>

                            </NavDropdown>
                        </th>
                        <th>Embarcacão/Modelo</th>
                        <th>Serviço</th>
                        <th>Comb</th>
                        <th>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Status"
                                menuVariant="dark"
                            >

                                <NavDropdown.Item onClick={() => atualizaFiltros({ statusAtendimento: 'Na Fila' })}>Na Fila</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ statusAtendimento: 'Em Atendimento' })}>Em Atendimento</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ statusAtendimento: 'Na Água' })}>Na Água</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ statusAtendimento: 'Subir' })}>Subir</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => atualizaFiltros({ statusAtendimento: 'Finalizado' })}>Finalizado</NavDropdown.Item>
                            </NavDropdown>
                        </th>
                    </tr>
                </thead>

                <tbody>

                    {dataTable?.map((item) => ( //usar index prejudica performance

                        <tr key={item?.id} style={{ backgroundColor: item?.dataPrevAtendimento !== null && item?.statusAtendimento === 'Na Fila' ? 'yellow' : 'white' }}>
                            <td>{item?.id}</td>
                            <td >{retornaAgendamento((item?.dataPrevAtendimento?.toString().slice(0, 10)), item?.dataSolicitacao?.toString().slice(0, 10), item.statusAtendimento)}</td>
                            <td>{item?.horaSolicitacao?.slice(0, 5)}</td>
                            <td>{item?.Cliente?.nome}</td>
                            <td>{item?.Embarcaco?.tipo}</td>
                            <td>{item?.Embarcaco?.id + `-` + item?.Embarcaco?.nome} {item?.Embarcaco?.modeloCascoEmb}</td>
                            <td>{retornaTipoServico(item)}</td>
                            <td onClick={() => item?.statusAbastecimento === 'Pendente' && abrirModalGas(item)}>
                                {item?.qtadeCombustivel}
                                <img height="10px" id={styles.imgGas} alt="gas" src={item?.statusAbastecimento === 'Pendente' ? redGas : item?.statusAbastecimento === 'Nao Solicitado' ? greyGas : greenGas} />

                            </td>
                            <td>
                                <DropDownButton
                                    title={item.statusAtendimento}
                                    variant={defineCorBadge(item.statusAtendimento)}
                                    itens={[{ statusAtendimento: 'Na Fila' },
                                    { statusAtendimento: 'Em Atendimento' },
                                    { statusAtendimento: 'Na Água' },
                                    { statusAtendimento: 'Subir' },
                                    { statusAtendimento: 'Finalizado' }
                                    ]}
                                    functionOnClick={atualizaStatusAtendimento}
                                    id={[item?.id, item?.Embarcaco?.id]}
                                    conditionBlock="Finalizado"

                                />
                            </td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item, props.roteDetalhar)}>+</Button></td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )


}

export default TableServicoRampa;