import React, { useState, useContext } from "react";
import { LimpezaContext } from "../../Context/LimpezaContext";
import { get } from "react-hook-form";

const DateRangeForm: React.FC = () => {
    const today = new Date().toISOString().split("T")[0];
    const [startDate, setStartDate] = useState<string>(today);
    const [endDate, setEndDate] = useState<string>(today);
    const [isStartFocused, setIsStartFocused] = useState(false);
    const [isEndFocused, setIsEndFocused] = useState(false);

    const { dataInicio, setDataInicio, dataFim, setDataFim, getCleaningByDate } = useContext(LimpezaContext);
    console.log('dataInicio = ', dataInicio);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Data de Início:", startDate);
        console.log("Data de Fim:", endDate);

        if (!startDate || !endDate) {
            alert("Por favor, selecione ambas as datas.");
            return;
        }

        if (startDate > endDate) {
            alert("A data de fim deve ser posterior ou igual à data de início.");
            return;
        }

        getCleaningByDate(startDate, endDate);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", padding: "20px", fontFamily: "'Poppins', sans-serif", height: "10vh", alignItems: "center", width: "100%" }}>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    padding: "15px",
                    borderRadius: "10px",
                    //boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" 
                }}
            >
                <input
                    type="date"
                    defaultValue={dataInicio} 
                    onChange={(e) => setStartDate(e.target.value)}
                    onFocus={() => setIsStartFocused(true)}
                    onBlur={() => setIsStartFocused(false)}
                    style={{
                        padding: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        color: startDate || isStartFocused ? "#000" : "#999"
                    }}
                />
                <input
                    type="date"
                    defaultValue={dataFim}
                    onChange={(e) => setEndDate(e.target.value)}
                    onFocus={() => setIsEndFocused(true)}
                    onBlur={() => setIsEndFocused(false)}
                    style={{
                        padding: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        outline: "none",
                        color: endDate || isEndFocused ? "#000" : "#999"
                    }}
                />
                <button
                    type="submit"
                    style={{
                        padding: "10px 15px",
                        fontSize: "16px",
                        cursor: "pointer",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        transition: "0.3s"
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#0056b3"}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#007bff"}
                >
                    Enviar
                </button>
            </form>
        </div>
    );
};

export default DateRangeForm;
